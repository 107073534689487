const dev = {
  apiUrl: 'https://entsorgungsdaten-api-dev.sketch-manager.de/',
  version: '0.0.12'
};
const prod = {
  apiUrl: 'https://entsorgungsdaten-api.meindl-entsorgung.de/',
  version: '1.0.2'
};

let config = prod;

if (process.env.NODE_ENV === 'production') {
  config = prod;
}



export default {
  // Add common config values here
  ...config
};
