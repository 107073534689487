<template>
  <div class="selectBoxes">
    <div class="box fullbox">
      <h3>{{ $t("reorder.pleaseSelect") }}</h3>
      <button class="btn active">{{ $t("usage." + $store.state.order.usage) }}</button>
      <label for="" class="countCoupons" v-if="$store.state.order.usage === 'c'"><input type="number" v-model="$store.state.order.countCoupons" min="1" step="1"  /> {{$t('reorder.countCoupons')}}</label>
      <input type="text" name="company" v-model="$store.state.order.address.c_company" v-if="$store.state.order.usage === 'c'" :placeholder="$t('address.company')"/>
      <input type="text" name="firstname" v-model="$store.state.order.address.c_firstname" :placeholder="$t('address.firstname')"/>
      <input type="text" name="lastname" v-model="$store.state.order.address.c_lastname" :placeholder="$t('address.lastname')"/>
      <input type="text" name="street" v-model="$store.state.order.address.c_street" :placeholder="$t('address.street')"/>
      <input type="text" name="number" v-model="$store.state.order.address.c_number" :placeholder="$t('address.number')"/>
<!--      <input type="text" v-model="$store.state.order.address.c_zipcode" :placeholder="$t('address.zip')" />-->
      <select name="zipcode" v-model="$store.state.order.address.c_zipcode">
        <option value="">{{$t('address.zip')}}</option>
        <option value="93047">93047</option>
        <option value="93049">93049</option>
        <option value="93051">93051</option>
        <option value="93053">93053</option>
        <option value="93055">93055</option>
        <option value="93057">93057</option>
        <option value="93059">93059</option>
      </select>
      <input type="text" value="Regensburg" disabled="disabled" class="disabled" />
      <input type="email" name="email" v-model="$store.state.order.address.c_email" :placeholder="$t('address.email')"/>
      <label for="ds"><input v-model="$store.state.order.acceptLegal" value="1" type="checkbox" id="ds"> <span v-html="$t('app.legalAccept')"></span></label>
    </div>
  </div>
 <div class="doubleButton">
  <button class="btn" @click="$router.push('/usage')">{{ $t("app.back") }}</button>
  <button class="btn" @click="validateAddress">{{ $t("app.next") }}</button>
 </div>
</template>
<script>
import { useToast } from "vue-toastification";
import axios   from "axios";
import environment from "@/auth/environment";
export default {
  name: "Address",
  methods: {
    validateAddress() {
      if (!this.$store.state.order.acceptLegal) {
        const toast = useToast();
        toast.error(this.$t("errors.errorLegal"));
        return;

      }
      if (this.$store.state.order.address.c_firstname.length > 0 && this.$store.state.order.address.c_lastname.length > 0 && this.$store.state.order.address.c_street.length > 0 && this.$store.state.order.address.c_zipcode.length > 0 && this.$store.state.order.address.c_number.length > 0) {

        axios.post(environment.apiUrl+"plugins/entsorgungsdaten/order", this.$store.state.order).then((response) => {
          if(this.$store.state.order.usage === "c") {

            // find id 2 from  this.$store.state.stations

            let a;
            for(a in  this.$store.state.stations) {
              if(this.$store.state.stations[a].id === '2') {
                this.$store.state.activeStation = this.$store.state.stations[a];
                this.$store.state.order.activeStation = this.$store.state.stations[a];
              }
            }
            this.$router.push("/reviewoutput");
          } else {
          this.$router.push("/outputs");
          }
        }).catch((error) => {
          this.$store.dispatch("handleApiResponse", error.response);
        })


      } else {
        const toast = useToast();
        toast.error(this.$t("address.error"));
      }
    }
  },
  mounted() {
    if (!this.$store.state.order.usage) {
      this.$router.push('/');
    }
    this.$store.state.progress = 65
  }
}
</script>
<style scoped lang="scss">
.countCoupons {
  display:block;
  margin-bottom: 12px;
  input {
    width:50px;
  }
}
</style>
